<template>
  <div class="Header w-full h-20 bg-white shadow fixed top-0 left-0 right-0 z-10 flex justify-between items-center px-6">
    <div class="flex items-center space-x-6">
      <div class="IconMenuDiHeader w-11 h-11 bg-white rounded-full"></div>
      <div class="LogoCalianaColor">
        <img class="h-7" src="https://via.placeholder.com/121x28" alt="Company Logo" />
      </div>
      <div class="Divider w-px h-14 bg-gray-300"></div>
      <div>
        <h2 class="text-gray-900 text-lg font-semibold">Halaman</h2>
        <div class="flex items-center space-x-2 text-sm text-gray-500">
          <span>Kantor A</span>
          <div class="Divider w-px h-6 bg-gray-300"></div>
          <span>Senin, 01 Januari 2023</span>
          <div class="Divider w-px h-6 bg-gray-300"></div>
          <span>00.00 WIB</span>
        </div>
      </div>
    </div>
    <div class="flex items-center space-x-6">
      <input type="text" placeholder="Temukan fitur yang Anda butuhkan" class="py-2 px-4 border border-gray-300 rounded-md" />
      <div class="relative">
        <img src="https://via.placeholder.com/42x42" class="w-11 h-11 rounded-full" alt="User Profile" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.Header {
  background-color: white;
  z-index: 10;
}

.Divider {
  background-color: #e5e5e5;
}
</style>
