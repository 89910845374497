<template>
  <div class="flex h-screen">
    <!-- Sidebar -->
    <Sidebar />

    <!-- Main content -->
    <div class="flex-grow bg-gray-50 pl-[246px]">
      <!-- Header -->
      <HeaderComponent />

      <!-- Dashboard Content -->
      <div class="p-6 mt-20">
        <h1 class="text-2xl font-bold text-gray-800 mb-4">Welcome to your Dashboard!</h1>
        <p class="text-gray-600 mb-6">
          Use the sidebar to navigate between different sections of the dashboard.
        </p>
        <div class="border-4 border-dashed border-gray-300 rounded-lg h-96 flex items-center justify-center">
          <!-- Placeholder for dynamic content -->
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/SidebarDashboard.vue';
import HeaderComponent from '@/components/HeaderDashboard.vue';

export default {
  name: 'DashboardView',
  components: {
    Sidebar,
    HeaderComponent,
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
</style>
