<template>
    <div class="flex">
      <!-- Sidebar -->
      <Sidebar />
  
      <!-- Main content -->
      <div class="flex-grow p-6">
        <h1 class="text-2xl font-bold mb-4">Role Management</h1>
        <p>Manage the roles and permissions for users accessing the dashboard.</p>
      </div>
    </div>
  </template>
  
  <script>
  import Sidebar from '@/components/SidebarDashboard.vue';
  
  export default {
    name: 'RoleManagementView',
    components: {
      Sidebar,
    },
  };
  </script>
  
  <style scoped>
  /* Add any necessary styles */
  </style>