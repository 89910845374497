<template>
  <div>
    <HeaderSection />

    <section class="py-10 px-6 bg-blue-50">
      <div class="container mx-auto">
        <!-- Title Section -->
        <h2 class="text-2xl font-bold text-gray-900 mb-4">1. DIVISI PABRIKASI</h2>
        <p class="text-gray-700 mb-4">
          Mengerjakan proses fabrikasi yang sudah dipersiapkan segala macam alat dan
          mesin-mesin untuk melakukan proses produksi dan pekerjaan-pekerjaan pabrikasi lainnya.
        </p>
        <div class="flex flex-wrap items-center mb-6">
          <span class="font-bold text-gray-900 mr-2">Manager :</span>
          <span class="text-red-600 font-semibold mr-6">- MUHAMAD YUDI SYARIF</span>
          <span class="text-red-600 font-semibold">- MULYADI / NGATEMAN</span>
          <span class="ml-auto font-semibold text-blue-800">
            Phone : 0812 8459 2221, 0822 1341 4825
          </span>
        </div>

        <!-- Main Work Images -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <img src="/pabrikasi/pabrikasi1.png" alt="Proses Pabrikasi 1" class="rounded-md shadow-md" />
          <img src="/pabrikasi/pabrikasi2.png" alt="Proses Pabrikasi 2" class="rounded-md shadow-md" />
        </div>

        <!-- Link to Google Drive as Button -->
        <div class="mt-6 text-center">
          <a
            href="https://drive.google.com/file/d/1vJqYqeEoTSZiEWWEzyNrqz08ZDowNYyz/view?usp=sharing"
            target="_blank"
            class="inline-block px-6 py-2 bg-green-500 text-white font-semibold rounded-lg shadow hover:bg-green-600 transition duration-300 ease-in-out"
          >
          Download Catalog
          </a>
        </div>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'DetailPabrikasi',
  components: {
    HeaderSection,
    FooterSection,
  },
  data() {
    return {
      tools: [
        { name: 'Drill Press', image: 'path/to/drill-press.jpg' },
        { name: 'Electric Screwdriver', image: 'path/to/screwdriver.jpg' },
        { name: 'Polisher', image: 'path/to/polisher.jpg' },
        { name: 'Grinder', image: 'path/to/grinder.jpg' },
        { name: 'Cutting Machine', image: 'path/to/cutting-machine.jpg' },
        { name: 'Welding Machine', image: 'path/to/welding-machine.jpg' },
      ],
    };
  },
};
</script>

<style scoped>
section {
  border-radius: 0.5rem;
}

img.rounded-full {
  max-width: 100px;
  max-height: 100px;
}
</style>
