<template>
  <header class="bg-white shadow-md">
    <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <!-- Logo Section -->
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Triasa</span>
          <!-- Ganti path logo sesuai lokasi file logo baru -->
          <img class="h-16 w-auto" src="/logo.png" alt="Company Logo" />
        </a>
      </div>

      <!-- Mobile Menu Button -->
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="toggleMenu">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <!-- Menu for larger screens -->
      <div class="hidden lg:flex lg:gap-x-12">
        <router-link
          v-for="item in navigation"
          :key="item.name"
          :to="item.href"
          class="text-sm font-semibold leading-6 text-gray-900 hover:text-green-500 transition"
        >
          {{ item.name }}
        </router-link>
      </div>

      <!-- Button for "Kontak Kami" -->
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a href="/contact" class="inline-block px-4 py-2 text-sm font-semibold leading-6 text-white bg-green-500 rounded-md hover:bg-green-600 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          Kontak Kami
        </a>
      </div>
    </nav>

    <!-- Mobile Menu -->
    <Dialog class="lg:hidden" @close="toggleMenu" :open="isMenuOpen">
      <div class="fixed inset-0 z-50" />
      <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Triasa</span>
            <img class="h-16 w-auto" src="/logo.png" alt="Company Logo" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="toggleMenu">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                @click="toggleMenu"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                {{ item.name }}
              </router-link>
            </div>
            <div class="py-6">
              <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

// Menu navigation links
const navigation = [
  { name: 'Beranda', href: '/' },
  { name: 'Layanan', href: '/productorservice' },
  { name: 'Galeri', href: '/galeri' },
  { name: 'Blog', href: '/blog' },
  { name: 'Tentang Kami', href: '/about' }, 
]

// Mobile menu toggle
const isMenuOpen = ref(false)

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value
}
</script>

<style scoped>
header {
  position: sticky;
  top: 0;
  z-index: 50;
}
</style>