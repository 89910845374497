<template>
  <section class="relative py-20 bg-gradient-to-b from-gray-50 to-white">
    <!-- Header pattern dengan warna TRI ASA -->
    <div class="absolute top-0 left-0 w-full h-16 bg-gradient-to-b from-red-600 to-red-500"></div>
    
    <div class="container mx-auto px-4 relative">
      <!-- Header -->
      <div class="flex flex-col sm:flex-row justify-between items-center mb-12 bg-white p-6 rounded-lg shadow-md border border-gray-200">
        <div class="mb-6 pb-4 border-b-2 border-red-500">
          <h3 class="text-3xl font-bold text-red-600">Layanan Kami</h3>
        </div>
        <router-link 
          to="/productorservice" 
          class="inline-block px-6 py-2 text-sm font-bold text-white 
                 bg-gradient-to-b from-red-500 to-red-600
                 hover:from-red-600 hover:to-red-700
                 rounded border border-red-700 shadow
                 transition-all duration-300"
        >
          Lihat selengkapnya →
        </router-link>
      </div>
      
      <!-- Products Grid -->
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <router-link 
          v-for="(product, index) in products" 
          :key="index" 
          :to="product.link" 
          class="group bg-white rounded-lg overflow-hidden transition-all duration-300 hover:translate-y-[-5px]"
        >
          <!-- Card dengan efek hover -->
          <div class="relative border border-gray-200 rounded-lg shadow-md hover:shadow-xl transition-all duration-300">
            <!-- Image container -->
            <div class="relative overflow-hidden">
              <div class="absolute inset-0 bg-gradient-to-t from-red-600/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              <img 
                :src="product.image" 
                :alt="product.name" 
                class="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
              >
            </div>
            
            <!-- Content -->
            <div class="p-6 bg-white">
              <h4 class="text-lg font-semibold text-gray-900 group-hover:text-red-600 transition-colors duration-300">
                {{ product.name }}
              </h4>
              <div class="mt-2 inline-block px-3 py-1 bg-red-100 text-red-600 text-sm rounded-full">
                {{ product.color }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Footer pattern -->
    <div class="absolute bottom-0 left-0 w-full">
      <div class="h-8 bg-gradient-to-b from-transparent to-gray-50"></div>
      <div class="h-6 bg-gradient-to-b from-gray-50 to-gray-100"></div>
    </div>
    
    <!-- Subtle texture overlay -->
    <div 
      class="absolute inset-0 opacity-5 pointer-events-none"
      :style="{
        backgroundImage: `url('data:image/svg+xml,%3Csvg width=\'6\' height=\'6\' viewBox=\'0 0 6 6\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23000000\' fill-opacity=\'1\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M5 0h1L0 6V5zM6 5v1H5z\'/%3E%3C/g%3E%3C/svg%3E')`,
        backgroundSize: '4px 4px'
      }"
    />
  </section>
</template>

<script>
export default {
  name: 'ProductsSection',
  data() {
    return {
      products: [
        {
          name: 'Pabrikasi',
          color: 'Divisi',
          image: '/produk/produk1.png',
          link: '/detail-pabrikasi'
        },
        {
          name: 'Machining Engineering',
          color: 'Divisi',
          image: '/produk/produk2.png',
          link: '/detail-engineering'
        },
        {
          name: 'Sipil',
          color: 'Divisi',
          image: '/produk/produk3.png',
          link: '/detail-sipil-konstruksi'
        },
        {
          name: 'Tenda & Terpal',
          color: 'Divisi',
          image: '/produk/produk4.png',
          link: '/detail-tenda-terpal'
        },
      ]
    };
  }
};
</script>

<style scoped>
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:scale-105 {
  transform: scale(1.05);
}

.group:hover .group-hover\:translate-y-\[-5px\] {
  transform: translateY(-5px);
}
</style>