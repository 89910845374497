<template>
    <div class="container mx-auto">
      <h1 class="text-4xl font-bold mb-4">Login</h1>
      <form @submit.prevent="login">
        <div class="mb-4">
          <label for="email" class="block text-gray-700">Email</label>
          <input v-model="email" type="email" id="email" class="w-full px-3 py-2 border rounded">
        </div>
        <div class="mb-4">
          <label for="password" class="block text-gray-700">Password</label>
          <input v-model="password" type="password" id="password" class="w-full px-3 py-2 border rounded">
        </div>
        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: ''
      };
    },
    methods: {
  login() {
    console.log('Login function triggered');
    if (this.email === 'admin@mail.com' && this.password === 'password') {
      console.log('Credentials are correct');
      localStorage.setItem('auth', true); // Set status autentikasi di localStorage
      this.$router.push('/dashboard'); // Redirect ke dashboard
    } else {
      alert('Invalid credentials');
      console.log('Invalid credentials');
    }
  }
}
  };
  </script>