import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import DashboardView from '../views/DashboardView.vue';
import PageControlView from '../views/PageControlView.vue';
import ProductManagementView from '../views/ProductManagementView.vue';
import AboutView from '../views/AboutView.vue';
import ProductOrServiceView from '../views/ProductOrServiceView.vue';
import BlogView from '../views/BlogView.vue';
import ContactView from '../views/ContactView.vue';
import BlogManagementView from '../views/BlogManagementView.vue';
import RoleManagementView from '../views/RoleManagementView.vue';
import GaleriView from '@/views/GaleriView.vue';
import DetailPabrikasi from '@/components/LandingPage/Detail/DetailPabrikasi.vue';
import DetailEngineering from '@/components/LandingPage/Detail/DetailEngineering.vue';
import DetailSipilKonstruksi from '@/components/LandingPage/Detail/DetailSipilKonstruksi.vue';
import DetailTendaTerpal from '@/components/LandingPage/Detail/DetailTendaTerpal.vue';
import DetailAlumuniumKaca from '@/components/LandingPage/Detail/DetailAlumuniumKaca.vue';
import DetailPowderCoating from '@/components/LandingPage/Detail/DetailPowderCoating.vue';

const routes = [
  
  { path: '/login', name: 'Login', component: LoginView },
  { path: '/', name: 'Home', component: HomeView },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
  },
  {
    path: '/productorservice',
    name: 'ProductOrService',
    component: ProductOrServiceView,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
  },
  {
    path: '/galeri',
    name: 'galeri',
    component: GaleriView,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/page-control',
    name: 'PageControl',
    component: PageControlView,
    meta: { requiresAuth: true },
  },
  {
    path: '/product-management',
    name: 'ProductManagement',
    component: ProductManagementView,
    meta: { requiresAuth: true },
  },
  {
    path: '/blog-management',
    name: 'BlogManagement',
    component: BlogManagementView,
    meta: { requiresAuth: true },
  },
  {
    path: '/role-management',
    name: 'RoleManagement',
    component: RoleManagementView,
    meta: { requiresAuth: true },
  },
  {
    path: '/detail-pabrikasi',
    name: 'DetailPabrikasi',
    component: DetailPabrikasi,
  },
  {
    path: '/detail-engineering',
    name: 'DetailEngineering',
    component: DetailEngineering,
  },
  {
    path: '/detail-sipil-konstruksi',
    name: 'DetailSipilKonstruksi',
    component: DetailSipilKonstruksi,
  },
  {
    path: '/detail-tenda-terpal',
    name: 'DetailTendaTerpal',
    component: DetailTendaTerpal,
  },
  {
    path: '/detail-alumunium-kaca',
    name: 'DetailAlumuniumKaca',
    component: DetailAlumuniumKaca,
  },
  {
    path: '/detail-powder-coating',
    name: 'DetailPowderCoating',
    component: DetailPowderCoating,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Middleware untuk mengecek apakah pengguna sudah login
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('auth'); // Menggunakan localStorage untuk menyimpan status login
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;