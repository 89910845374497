<template>
  <div>
    <HeaderSection />
    <BannerSection />
    <AboutUsSection />
    <ProductsSection />
    <TestimonialsSection />
    <BlogPostsSection />
    <ContactSection />
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import BannerSection from '@/components/LandingPage/BannerSection.vue';
import AboutUsSection from '@/components/LandingPage/AboutUsSection.vue';
import ProductsSection from '@/components/LandingPage/ProductsSection.vue';
import TestimonialsSection from '@/components/LandingPage/TestimonialsSection.vue';
import BlogPostsSection from '@/components/LandingPage/BlogPostsSection.vue';
import ContactSection from '@/components/LandingPage/ContactSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  components: {
    HeaderSection,
    BannerSection,
    AboutUsSection,
    ProductsSection,
    TestimonialsSection,
    BlogPostsSection,
    ContactSection,
    FooterSection,
  },
};
</script>

<style scoped>
/* Tambahkan style khusus untuk landing page jika diperlukan */
</style>