<template>
  <div>
    <HeaderSection />

    <!-- Blog Section -->
    <section class="py-16 bg-gray-50">
      <div class="container mx-auto">
        <!-- Heading -->
        <div class="text-left">
          <h2 class="text-4xl font-bold text-gray-900 mb-4">Artikel Tentang Kami</h2>
          <p class="text-gray-600 mb-12 max-w-xl">
            Pelajari lebih lanjut tentang kami melalui artikel-artikel yang kami tulis untuk memberi Anda wawasan tentang visi, misi, dan layanan kami.
          </p>
        </div>

          
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'BlogView',
  components: {
    HeaderSection,
    FooterSection,
  },
};
</script>

<style scoped>
/* Styling tambahan */
.bg-white:hover {
  transform: translateY(-5px);
}

img {
  object-fit: cover;
}

.text-green-600 {
  color: #04B0EA; /* Sesuaikan dengan warna hijau yang diinginkan */
}
</style>
