<template>
  <footer class="bg-white py-12">
    <div class="container mx-auto px-6 lg:px-8">
      <div class="flex flex-col items-center text-center lg:flex-row lg:items-start lg:text-left lg:justify-between">
        
        <!-- Logo dan Deskripsi -->
        <div class="mb-10 lg:mb-0 lg:w-1/3">
          <img src="/logo.png" alt="Company Logo" class="h-8 mb-4 mx-auto lg:mx-0">
          <p class="text-gray-500 text-sm"></p>
          <!-- Social Media Icons -->
          <div class="mt-4 flex justify-center lg:justify-start space-x-4">
            <!--
            <a href="#" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Facebook</span>
              <i class="fab fa-facebook-f"></i>
            </a>
            -->
            <a href="https://www.instagram.com/indonesiatriasa?igsh=bXJvbW16ZWJiN2lq" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Instagram</span>
              <i class="fab fa-instagram"></i>
            </a>
            <!--
            <a href="#" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">X (Twitter)</span>
              <i class="fab fa-twitter"></i>
            </a>
            -->
            <a href="https://youtube.com/@bengkelastriasa?si=vDMPGn8ZcXJ9P8kc" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">YouTube</span>
              <i class="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div class="mt-12 border-t border-gray-200 pt-8 text-sm text-gray-500 text-center">
        &copy; 2024 PT Tri Asa Indonesia. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection',
};
</script>

<style scoped>
/* Add additional styles if needed */
</style>