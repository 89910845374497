<template>
  <div>
    <HeaderSection />

    <!-- Contact Information Section -->
    <section class="py-12 bg-gray-50">
      <div class="container mx-auto text-center">
        <h2 class="text-4xl font-bold text-gray-900 mb-4">Kontak kami</h2>
        <p class="text-gray-600 mb-12">Silahkan kunjungi atau kontak kami melalui</p>

        <!-- Office Addresses in 4 Columns -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <h3 class="text-xl font-semibold text-gray-900 mb-2">Office & Workshop 1</h3>
            <p class="text-gray-500">JL. Nur Hikmah Kp. Sawah RT 02/03, Jatimelati, Pondok Melati, Kota Bekasi</p>
          </div>
          <div>
            <h3 class="text-xl font-semibold text-gray-900 mb-2">Workshop 2</h3>
            <p class="text-gray-500">JL. H. Yasin RT 05/011 Jatiluhur, Jatiasih, Kota Bekasi</p>
          </div>
          <div>
            <h3 class="text-xl font-semibold text-gray-900 mb-2">Workshop 3</h3>
            <p class="text-gray-500">Ruko No. 1-11 Kav. 2, Jalan Bekasi Timur Raya RT 5/1 Jatinegara Kaum Pulo Gadung - Jakarta Timur.</p>
          </div>
          <div>
            <h3 class="text-xl font-semibold text-gray-900 mb-2">Telepon</h3>
            <p class="text-gray-500">0812-8459-2221 <br> 0878-8677-8202 <br> 0856-9746-4325</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact Form Section -->
    <section class="py-12 bg-white">
      <div class="container mx-auto">
        <h2 class="text-3xl font-bold text-center text-gray-900 mb-8">Get in Touch</h2>
        <form class="max-w-2xl mx-auto space-y-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label class="block text-sm font-medium text-gray-700">Your Name</label>
              <input type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="John Doe">
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">Your Email</label>
              <input type="email" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="you@example.com">
            </div>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">Company</label>
            <input type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your Company">
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">Message</label>
            <textarea rows="4" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your message here..."></textarea>
          </div>

          <div class="text-center">
            <button type="submit" class="px-6 py-2 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'ContactView',
  components: {
    HeaderSection,
    FooterSection,
  },
};
</script>

<style scoped>
/* Optional: additional padding or styles */
</style>