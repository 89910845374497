<template>
  <div>
    <HeaderSection />

    <section class="py-10 px-6 bg-blue-50">
      <div class="container mx-auto">
        <!-- Title Section -->
        <h2 class="text-2xl font-bold text-gray-900 mb-4">4. DIVISI TENDA & TERPAL</h2>
        <p class="text-gray-700 mb-4">
          Divisi ini adalah bagian yang khusus untuk mengerjaan pembuatan tenda & terpal.
        </p>
        <div class="flex flex-wrap items-center mb-6">
          <span class="font-bold text-gray-900 mr-2">Manager :</span>
          <span class="text-pink-600 font-semibold mr-6">IMAM ROYANI BN IBRAHIM</span>
          <span class="ml-auto font-semibold text-blue-800">
            Phone : 0813 2233 7787
          </span>
        </div>

        <!-- Tent and Canopy Images Section -->
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
          <div v-for="(tent, index) in tents" :key="index" class="flex justify-center">
            <img :src="tent.image" :alt="tent.description" class="border-2 border-blue-300 w-full h-48 object-cover rounded-md" />
          </div>
        </div>

        <!-- Link to Google Drive as Button -->
        <div class="mt-6 text-center">
          <a
            href="https://drive.google.com/file/d/1vJqYqeEoTSZiEWWEzyNrqz08ZDowNYyz/view?usp=sharing"
            target="_blank"
            class="inline-block px-6 py-2 bg-green-500 text-white font-semibold rounded-lg shadow hover:bg-green-600 transition duration-300 ease-in-out"
          >
          Download Catalog
          </a>
        </div>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'DetailTendaTerpal',
  components: {
    HeaderSection,
    FooterSection,
  },
  data() {
    return {
      tents: [
        { description: 'Tenda Utama', image: '/tendaterpal/tendaterpal1.png' },
        { description: 'Tenda Biru', image: '/tendaterpal/tendaterpal2.png' },
        { description: 'Tenda Putih', image: '/tendaterpal/tendaterpal1.png' },
      ],
    };
  },
};
</script>

<style scoped>
section {
  border-radius: 0.5rem;
}

img.rounded-md {
  max-width: 100%;
  height: auto;
}
</style>
