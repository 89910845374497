<template>
  <div class="Sidebar w-[246px] h-full bg-white shadow fixed top-0 left-0 z-10 pt-20">
    <nav class="mt-6">
      <ul class="space-y-4">
        <!-- Dashboard -->
        <li>
          <router-link
            to="/dashboard"
            class="ButtonPageDiSidebar flex items-center gap-2 px-4 py-2 rounded-lg"
            :class="{ 'bg-blue-100 text-blue-500': $route.path === '/dashboard' }"
          >
            <div class="DashboardActiveUnactive w-6 h-6 bg-gray-300 rounded-full"></div>
            <span class="text-gray-500 text-sm">Dashboard</span>
          </router-link>
        </li>

        <!-- Product Management -->
        <li>
          <router-link
            to="/product-management"
            class="ButtonPageDiSidebar flex items-center gap-2 px-4 py-2 rounded-lg"
            :class="{ 'bg-blue-100 text-blue-500': $route.path === '/product-management' }"
          >
            <div class="DashboardActiveUnactive w-6 h-6 bg-gray-300 rounded-full"></div>
            <span class="text-gray-500 text-sm">Product Management</span>
          </router-link>
        </li>

        <!-- Page Control -->
        <li>
          <router-link
            to="/page-control"
            class="ButtonPageDiSidebar flex items-center gap-2 px-4 py-2 rounded-lg"
            :class="{ 'bg-blue-100 text-blue-500': $route.path === '/page-control' }"
          >
            <div class="VisitorActiveUnactive w-6 h-6 bg-gray-300 rounded-full"></div>
            <span class="text-gray-500 text-sm">Page Control</span>
          </router-link>
        </li>

        <!-- Blog Management -->
        <li>
          <router-link
            to="/blog-management"
            class="ButtonPageDiSidebar flex items-center gap-2 px-4 py-2 rounded-lg"
            :class="{ 'bg-blue-100 text-blue-500': $route.path === '/blog-management' }"
          >
            <div class="FormActiveUnactive w-6 h-6 bg-gray-300 rounded-full"></div>
            <span class="text-gray-500 text-sm">Blog Management</span>
          </router-link>
        </li>

        <!-- Role Management -->
        <li>
          <router-link
            to="/role-management"
            class="ButtonPageDiSidebar flex items-center gap-2 px-4 py-2 rounded-lg"
            :class="{ 'bg-blue-100 text-blue-500': $route.path === '/role-management' }"
          >
            <div class="OperatorActiveUnactive w-6 h-6 bg-gray-300 rounded-full"></div>
            <span class="text-gray-500 text-sm">Role Management</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>
.Sidebar {
  background-color: white;
  height: 100vh;
}

.ButtonPageDiSidebar {
  cursor: pointer;
}

.ButtonPageDiSidebar:hover {
  background-color: #f0f0f0;
}

.ButtonPageDiSidebar.bg-blue-100 {
  background-color: rgba(0, 144, 232, 0.1); /* Biru transparan */
}

.HomeActiveUnactive {
  background-color: #0090e8;
}

.Vms {
  background-color: #04b0ea;
}
</style>
