<template>
  <section class="py-20 bg-gray-100">
    <div class="container mx-auto">
      <div class="flex justify-between items-center mb-12">
        <div>
          <h3 class="text-3xl font-bold text-gray-900">Channel YouTube</h3>
          <p class="text-gray-600">Video tutorial terkait dengan produk kami.</p>
        </div>
        <a href="https://www.youtube.com/channel/YOUR_CHANNEL_ID" target="_blank" class="text-green-500 hover:text-green-600 font-semibold text-lg"></a>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="(video, index) in videos" :key="index" class="bg-white shadow rounded overflow-hidden">
          <div class="relative pb-[56.25%] h-0">
            <iframe
              class="absolute top-0 left-0 w-full h-full"
              :src="'https://www.youtube.com/embed/' + video.youtubeId"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="p-4">
            <h4 class="text-xl font-semibold text-gray-900 mb-2">{{ video.title }}</h4>
            <p class="text-gray-600 mb-4">{{ video.description }}</p>
            <div class="flex items-center justify-between">
              <div class="text-sm text-gray-500">{{ video.date }}</div>
              <div class="text-sm font-semibold text-gray-900">{{ video.views }} views</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'YouTubeTutorialsSection',
  data() {
    return {
      videos: [
        {
          title: 'Bengkel las terlengkap di Bekasi',
          youtubeId: 'BsGvrDwPfQE', // Gunakan ID video saja
          description: 'Solusi untuk Kebutuhan Konstruksi dan Pabrikasi Besi.',
          date: 'Mar 16, 2023',
          views: '15K'
        },
        {
          title: 'Bengkel las terlengkap di Bekasi',
          youtubeId: 'BsGvrDwPfQE', // Gunakan ID video saja
          description: 'Solusi untuk Kebutuhan Konstruksi dan Pabrikasi Besi.',
          date: 'Mar 10, 2023',
          views: '22K'
        },
        {
          title: 'Bengkel las terlengkap di Bekasi',
          youtubeId: 'BsGvrDwPfQE', // Gunakan ID video saja
          description: 'Solusi untuk Kebutuhan Konstruksi dan Pabrikasi Besi.',
          date: 'Feb 12, 2023',
          views: '18K'
        },
        // Anda bisa menambahkan lebih banyak video di sini
      ]
    };
  }
};
</script>

<style scoped>
/* Custom styling if necessary */
</style>
