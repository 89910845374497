<template>
  <section class="relative w-full h-[calc(100vh-100px)] overflow-hidden">
    <!-- Slider Container -->
    <div
      class="slider flex transition-transform duration-1000 ease-in-out"
      :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
    >
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="relative min-w-full h-full bg-cover bg-center flex items-center justify-center text-white"
        :style="{ backgroundImage: `url(${slide.image})` }"
      >
        <!-- Button Only -->
        <router-link
          :to="slide.link"
          class="relative z-10 px-6 py-3 text-sm font-semibold text-white bg-green-500 rounded-lg shadow-lg hover:bg-green-600 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50"
        >
          Konsultasikan Kebutuhan Anda Dengan Kami
        </router-link>
      </div>
    </div>

    <!-- Pagination Dots -->
    <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-3">
      <span
        v-for="(slide, index) in slides"
        :key="index"
        @click="goToSlide(index)"
        class="w-4 h-4 bg-white rounded-full cursor-pointer transition-colors duration-200 ease-in-out transform hover:scale-110"
        :class="{ 'bg-green-500': currentSlide === index }"
      ></span>
    </div>
  </section>
</template>

<script>
export default {
  name: "BannerSection",
  data() {
    return {
      currentSlide: 0,
      interval: null,
      slides: [
        {
          image: "/slide/slide1.png",
          link: "/",
        },
        {
          image: "/slide/slide2.png",
          link: "/",
        },
        {
          image: "/slide/slide3.png",
          link: "/",
        },
      ],
    };
  },
  mounted() {
    this.startAutoSlide();
  },
  methods: {
    startAutoSlide() {
      this.interval = setInterval(() => {
        this.nextSlide();
      }, 5000);
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    stopAutoSlide() {
      clearInterval(this.interval);
    },
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
};
</script>

<style scoped>
.slider {
  width: 100%;
  height: 100%;
}
</style>
