<template>
  <div id="app">
    <!-- Router View: Menampilkan konten berdasarkan rute -->
    <router-view />

    <!-- Floating WhatsApp Button -->
    <FloatingWhatsAppButton />
  </div>
</template>

<script>
import FloatingWhatsAppButton from "@/components/FloatingWhatsAppButton.vue";

export default {
  name: 'App',
  components: {
    FloatingWhatsAppButton, // Menggunakan komponen Floating WhatsApp Button
  },
};
</script>

<style>
/* Tambahkan style global di sini jika diperlukan */
</style>
