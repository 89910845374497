<template>
  <div>
    <HeaderSection />

    <!-- Gallery Section -->
    <section class="py-12 bg-gray-50">
      <div class="container mx-auto text-center">
        <h2 class="text-4xl font-bold text-gray-900 mb-4">Galeri Produk</h2>
        <p class="text-gray-600 mb-12"></p>

        <!-- Gallery Grid -->
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          <div
            v-for="image in galleryImages"
            :key="image.id"
            class="relative group cursor-pointer"
            @click="navigateToService"
          >
            <img
              :src="image.src"
              :alt="image.alt"
              class="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        </div>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'GaleriView',
  components: {
    HeaderSection,
    FooterSection,
  },
  setup() {
    const router = useRouter();

    const navigateToService = () => {
      router.push('/productorservice');
    };

    return {
      navigateToService,
    };
  },
  data() {
    return {
      galleryImages: [
        { id: 1, src: '/produk/produk1.png', alt: 'Image 1' },
        { id: 2, src: '/produk/produk2.png', alt: 'Image 2' },
        { id: 3, src: '/produk/produk3.png', alt: 'Image 3' },
        { id: 4, src: '/produk/produk4.png', alt: 'Image 4' },
        { id: 5, src: '/produk/produk5.png', alt: 'Image 5' },
        { id: 6, src: '/produk/produk6.png', alt: 'Image 6' },
        { id: 7, src: '/produk/produk7.png', alt: 'Image 7' },
        { id: 8, src: '/produk/produk8.png', alt: 'Image 8' },
        { id: 9, src: '/produk/produk9.png', alt: 'Image 9' },
        { id: 10, src: '/produk/produk10.png', alt: 'Image 10' },
        { id: 11, src: '/produk/produk11.png', alt: 'Image 11' },
        { id: 12, src: '/produk/produk12.png', alt: 'Image 12' },
        { id: 13, src: '/produk/produk13.png', alt: 'Image 13' },
        { id: 14, src: '/produk/produk14.png', alt: 'Image 14' },
        { id: 15, src: '/produk/produk15.png', alt: 'Image 15' },
        { id: 16, src: '/produk/produk16.png', alt: 'Image 16' },
        { id: 17, src: '/produk/produk17.png', alt: 'Image 17' },
        { id: 18, src: '/produk/produk18.png', alt: 'Image 18' },
        { id: 19, src: '/produk/produk19.png', alt: 'Image 19' },
        { id: 20, src: '/produk/produk20.png', alt: 'Image 20' },
        { id: 21, src: '/produk/produk21.png', alt: 'Image 21' },
        { id: 22, src: '/produk/produk22.png', alt: 'Image 22' },
      ],
    };
  },
};
</script>

<style scoped>
/* Styling tambahan untuk tampilan galeri */
.gallery-image {
  transition: transform 0.3s ease-in-out;
}

.gallery-image:hover {
  transform: scale(1.05);
}
</style>