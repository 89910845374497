<template>
  <div>
    <HeaderSection />

    <!-- Image Section with Overlay -->
    <div class="relative">
      <img src="/slide/slide3.png" alt="Our Team" class="w-full h-auto object-cover rounded-lg">
      <div class="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
        <h1 class="text-5xl font-bold text-white"></h1>
      </div>
    </div>

    <!-- Values Section -->
    <section class="py-16 bg-gray-100">
      <div class="container mx-auto text-center">
        <h2 class="text-4xl font-bold text-gray-900 mb-6">Tentang Kami</h2>
        <p class="text-gray-600 mb-12 px-4">
          Didirikan pada tahun 2010, tujuannya adalah untuk berpartisipasi dalam kegiatan di beberapa perusahaan/pabrik dengan menerapkan keterampilan dan pengalaman yang dimiliki oleh para ahli profesional. Melaksanakan kegiatan di beberapa perusahaan yang dapat secara tepat mencapai targetnya memerlukan manajemen yang cermat, sehingga semua sumber daya yang tersedia dapat dimanfaatkan dengan sebaik-baiknya. Dalam menjalankan kegiatan usahanya, PT. TRI ASA INDONESIA melakukan kerja sama teknis berdasarkan kebutuhan/persyaratan perusahaan secara efisien, tepat waktu dan dengan kualitas terbaik. Semuanya ditangani oleh para ahli yang berpengalaman, berkualitas, dan profesional.
        </p>

        
        <h3 class="text-xl font-semibold text-gray-800 mb-3">Visi & Misi</h3>
        <p class="text-gray-600 mb-12 px-4">
          Menjadi yang terdepan dalam bidang pemberdayaan Management Sumber Daya Manusia dalam memberikan nilai kepuasan terbaik dan berkualitas bagi pelanggan.
        </p>

        
        

        <!-- Values Cards -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
          <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
            <h3 class="text-xl font-semibold text-gray-800 mb-3">Inovasi Produk</h3>
            <p class="text-gray-600">Mewujudkan impian konsumen akan produk dan layanan berkualitas melalui inovasi, sistem manajemen, dan sumber daya manusia yang unggul.</p>
          </div>

          <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
            <h3 class="text-xl font-semibold text-gray-800 mb-3">Kemitraan Berkelanjutan</h3>
            <p class="text-gray-600">Menjalin kemitraan kerja yang optimal dan berkelanjutan dengan berbagai pihak dan distributor/agen yang saling menguntungkan.</p>
          </div>

          <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
            <h3 class="text-xl font-semibold text-gray-800 mb-3">Tanggung Jawab Sosial</h3>
            <p class="text-gray-600">Memberikan perhatian yang tulus kepada masyarakat melalui penciptaan lapangan kerja, dukungan pengembangan sosial, pendidikan, dan lingkungan.</p>
          </div>
        </div>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'AboutView',
  components: {
    HeaderSection,
    FooterSection,
  },
};
</script>

<style scoped>
/* Additional styles for the image section */
.relative img {
  max-height: 500px;
  border-radius: 10px;
  object-fit: cover;
}
.relative .absolute {
  border-radius: 10px;
}

/* Value cards hover effect */
section {
  border-radius: 0.5rem;
  min-height: 400px;
}
</style>
