<template>
  <section class="relative py-20 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
    <!-- Header pattern -->
    <div class="absolute top-0 left-0 w-full h-16 bg-gradient-to-b from-red-600 to-red-500"></div>
    
    <div class="container mx-auto px-4 relative">
      <!-- Section Header -->
      <div class="text-center mb-16">
        <div class="inline-block bg-white rounded-lg shadow-md border border-gray-200 px-8 py-4">
          <h3 class="text-4xl font-bold text-red-600 relative">
            Our Customers
            <span class="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 to-red-600"></span>
          </h3>
        </div>
      </div>
      
      <!-- Slider Container -->
      <div class="relative bg-white rounded-xl shadow-lg border border-gray-200 p-8">
        <!-- Gradient Edges -->
        <div class="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-white to-transparent z-10"></div>
        <div class="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-white to-transparent z-10"></div>
        
        <!-- Slider -->
        <div class="relative overflow-hidden">
          <div 
            class="flex transition-transform duration-700 ease-in-out"
            :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
          >
            <div 
              v-for="(_, slideIndex) in totalSlides" 
              :key="slideIndex" 
              class="flex justify-center items-center space-x-12 min-w-full"
            >
              <div 
                v-for="(logo, logoIndex) in getLogosForSlide(slideIndex)" 
                :key="logoIndex" 
                class="w-1/5 group"
              >
                <!-- Logo Container -->
                <div class="relative p-4 transform transition-all duration-300 hover:scale-110">
                  <!-- Logo Background -->
                  <div class="absolute inset-0 bg-gradient-to-br from-gray-50 to-white rounded-lg shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  
                  <!-- Logo Image -->
                  <img 
                    :src="logo.src" 
                    :alt="logo.alt" 
                    class="mx-auto h-20 object-contain filter transition-all duration-300 group-hover:brightness-110 relative z-10"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Dots -->
        <div class="flex justify-center mt-8 space-x-3">
          <button
            v-for="(_, index) in totalSlides"
            :key="index"
            @click="setCurrentSlide(index)"
            class="group focus:outline-none"
            :aria-label="`Go to slide ${index + 1}`"
          >
            <div
              :class="[
                'w-4 h-4 rounded-full transition-all duration-300 border-2',
                currentSlide === index 
                  ? 'bg-red-500 border-red-500' 
                  : 'bg-white border-gray-300 group-hover:border-red-300'
              ]"
            ></div>
          </button>
        </div>
      </div>

      <!-- Navigation Arrows -->
      <button 
        @click="prevSlide" 
        class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg border border-gray-200 hover:bg-red-50 transition-colors duration-300 z-20"
      >
        <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>

      <button 
        @click="nextSlide" 
        class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg border border-gray-200 hover:bg-red-50 transition-colors duration-300 z-20"
      >
        <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>

    <!-- Footer pattern -->
    <div class="absolute bottom-0 left-0 w-full">
      <div class="h-8 bg-gradient-to-b from-transparent to-gray-50"></div>
      <div class="h-6 bg-gradient-to-b from-gray-50 to-gray-100"></div>
    </div>

    <!-- Subtle texture overlay -->
    <div 
      class="absolute inset-0 opacity-5 pointer-events-none"
      :style="{
        backgroundImage: `url('data:image/svg+xml,%3Csvg width=\'6\' height=\'6\' viewBox=\'0 0 6 6\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23000000\' fill-opacity=\'1\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M5 0h1L0 6V5zM6 5v1H5z\'/%3E%3C/g%3E%3C/svg%3E')`,
        backgroundSize: '4px 4px'
      }"
    />
  </section>
</template>

<script>
export default {
  name: 'TestimonialsSection',
  data() {
    return {
      currentSlide: 0,
      logos: [
        { src: 'client/client1.png', alt: 'Client 1' },
        { src: 'client/client2.png', alt: 'Client 2' },
        { src: 'client/client3.png', alt: 'Client 3' },
        { src: 'client/client4.png', alt: 'Client 4' },
        { src: 'client/client5.png', alt: 'Client 5' },
        { src: 'client/client6.png', alt: 'Client 6' },
        { src: 'client/client7.png', alt: 'Client 7' },
        { src: 'client/client8.png', alt: 'Client 8' },
        { src: 'client/client9.png', alt: 'Client 9' },
        { src: 'client/client10.png', alt: 'Client 10' },
      ],
      autoPlayInterval: null
    };
  },
  computed: {
    totalSlides() {
      return Math.ceil(this.logos.length / 5);
    }
  },
  methods: {
    setCurrentSlide(index) {
      this.currentSlide = index;
      this.resetAutoPlay();
    },
    getLogosForSlide(slideIndex) {
      return this.logos.slice(slideIndex * 5, slideIndex * 5 + 5);
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.totalSlides) % this.totalSlides;
      this.resetAutoPlay();
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.totalSlides;
      this.resetAutoPlay();
    },
    resetAutoPlay() {
      if (this.autoPlayInterval) {
        clearInterval(this.autoPlayInterval);
      }
      this.startAutoPlay();
    },
    startAutoPlay() {
      this.autoPlayInterval = setInterval(() => {
        this.nextSlide();
      }, 5000);
    }
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeUnmount() {
    if (this.autoPlayInterval) {
      clearInterval(this.autoPlayInterval);
    }
  }
};
</script>

<style scoped>
/* Tambahkan transisi smooth untuk slider */
.slider-transition {
  transition: transform 0.5s ease-in-out;
}

/* Hover effects untuk logo */
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:scale-110 {
  transform: scale(1.1);
}

/* Animasi untuk dots navigasi */
.dot-transition {
  transition: all 0.3s ease-in-out;
}

/* Optional: Tambahkan animasi fade untuk gradient edges */
@keyframes fadeInOut {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.4; }
}

.gradient-edge {
  animation: fadeInOut 3s infinite;
}
</style>