<template>
  <div>
    <HeaderSection />

    <section class="py-10 px-6 bg-blue-50">
      <div class="container mx-auto">
        <!-- Title Section -->
        <h2 class="text-2xl font-bold text-gray-900 mb-4">6. DIVISI POWDER COATING</h2>
        <p class="text-gray-700 mb-4">
          Pengecatan kering, pelapisan dengan suatu film. Dalam bentuk film bubuk digunakan dalam lapisan tipis workpiece kemudian dilarutkan dalam bentuk film dan dipanaskan untuk polimerisasi dan mengawetkan coating. Powder dilekatkan pada permukaan profil aluminium/Besi dengan menggunakan alat electric spray gun.
        </p>
        <div class="flex flex-wrap items-center mb-6">
          <span class="font-bold text-gray-900 mr-2">Manager :</span>
          <span class="text-pink-600 font-semibold mr-6">ADI</span>
          <span class="ml-auto font-semibold text-blue-800">
            Phone : 0895 3600 48435
          </span>
        </div>

        

        <!-- Powder Coating Work Images Section -->
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
          <div v-for="(work, index) in powderCoatingWorks" :key="index" class="flex justify-center">
            <img :src="work.image" :alt="work.description" class="border-2 border-blue-300 w-full h-48 object-cover rounded-md" />
          </div>
        </div>

        <!-- Link to Google Drive as Button -->
        <div class="mt-6 text-center">
          <a
            href="https://drive.google.com/file/d/1vJqYqeEoTSZiEWWEzyNrqz08ZDowNYyz/view?usp=sharing"
            target="_blank"
            class="inline-block px-6 py-2 bg-green-500 text-white font-semibold rounded-lg shadow hover:bg-green-600 transition duration-300 ease-in-out"
          >
          Download Catalog
          </a>
        </div>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'DetailPowderCoating',
  components: {
    HeaderSection,
    FooterSection,
  },
  data() {
    return {
      powderCoatingWorks: [
        { description: 'Proses Powder Coating', image: '/powdercoating/powdercoating1.png' },
        { description: 'Oven Pengeringan', image: '/powdercoating/powdercoating2.png' },
        { description: 'Rak Powder Coating', image: '/powdercoating/powdercoating3.png' },
      ],
    };
  },
};
</script>

<style scoped>
section {
  border-radius: 0.5rem;
}

img.rounded-md {
  max-width: 100%;
  height: auto;
}
</style>
