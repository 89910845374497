<template>
  <section class="relative py-20 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
    <!-- Header pattern -->
    <div class="absolute top-0 left-0 w-full h-16 bg-gradient-to-b from-red-600 to-red-500"></div>
    
    <div class="container mx-auto px-4 relative">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <!-- Form Section -->
        <div class="bg-white rounded-xl shadow-lg border border-gray-200 p-8">
          <div class="mb-8">
            <h3 class="text-3xl font-bold text-red-600 relative inline-block">
              Kontak Kami
              <span class="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 to-red-600"></span>
            </h3>
            <p class="text-gray-600 mt-4">Konsultasikan kebutuhan Anda dengan Kami.</p>
          </div>

          <form class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label for="Nama Lengkap" class="sr-only">Nama Lengkap</label>
              <input 
                id="Nama Lengkap" 
                type="text" 
                placeholder="Nama Lengkap" 
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all duration-300"
              >
            </div>
            <div>
              <label for="Perusahaan" class="sr-only">Perusahaan</label>
              <input 
                id="Perusahaan" 
                type="text" 
                placeholder="Perusahaan" 
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all duration-300"
              >
            </div>
            <div>
              <label for="Email" class="sr-only">Email</label>
              <input 
                id="Email" 
                type="email" 
                placeholder="Email" 
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all duration-300"
              >
            </div>
            <div>
              <label for="Nomer Handphone" class="sr-only">Nomer Handphone</label>
              <input 
                id="Nomer Handphone" 
                type="tel" 
                placeholder="Nomer Handphone" 
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all duration-300"
              >
            </div>
            <div class="md:col-span-2">
              <label for="Pesan" class="sr-only">Pesan</label>
              <textarea 
                id="Pesan" 
                placeholder="Pesan" 
                class="w-full px-4 py-3 border border-gray-300 rounded-lg h-32 focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all duration-300"
              ></textarea>
            </div>
            <div class="md:col-span-2">
              <button 
                type="submit" 
                class="w-full px-6 py-3 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-lg hover:from-red-600 hover:to-red-700 transform hover:scale-[1.02] transition-all duration-300 shadow-md hover:shadow-lg"
              >
                Hubungi kami
              </button>
            </div>
            <div class="md:col-span-2 text-left text-sm mt-2">
              <p class="text-gray-600">
                Dengan Formulir ini, saya setuju untuk 
                <a href="#" class="text-red-500 hover:text-red-600 hover:underline transition-colors duration-300">privacy policy</a>.
              </p>
            </div>
          </form>
        </div>

        <!-- Contact Information Section -->
        <div class="bg-white rounded-xl shadow-lg border border-gray-200 p-8">
          <h4 class="text-2xl font-bold text-red-600 mb-8 relative inline-block">
            Informasi Kontak
            <span class="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 to-red-600"></span>
          </h4>
          
          <div class="space-y-6">
            <div class="flex items-start group">
              <div class="p-3 bg-red-50 rounded-lg group-hover:bg-red-100 transition-colors duration-300">
                <svg class="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                </svg>
              </div>
              <div class="ml-4">
                <h5 class="font-semibold text-gray-900">Telepon</h5>
                <p class="text-gray-600 hover:text-red-500 transition-colors duration-300">0812-8459-2221</p>
              </div>
            </div>

            <div class="flex items-start group">
              <div class="p-3 bg-red-50 rounded-lg group-hover:bg-red-100 transition-colors duration-300">
                <svg class="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                </svg>
              </div>
              <div class="ml-4">
                <h5 class="font-semibold text-gray-900">Email</h5>
                <p class="text-gray-600 hover:text-red-500 transition-colors duration-300">info@triasa.co.id</p>
              </div>
            </div>

            <div class="flex items-start group">
              <div class="p-3 bg-red-50 rounded-lg group-hover:bg-red-100 transition-colors duration-300">
                <svg class="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                </svg>
              </div>
              <div class="ml-4">
                <h5 class="font-semibold text-gray-900">Alamat</h5>
                <p class="text-gray-600">JL. Nur Hikmah Kp. Sawah RT 02/03, Jatimelati, Pondok Melati, Kota Bekasi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer pattern -->
    <div class="absolute bottom-0 left-0 w-full">
      <div class="h-8 bg-gradient-to-b from-transparent to-gray-50"></div>
      <div class="h-6 bg-gradient-to-b from-gray-50 to-gray-100"></div>
    </div>

    <!-- Subtle texture overlay -->
    <div 
      class="absolute inset-0 opacity-5 pointer-events-none"
      :style="{
        backgroundImage: `url('data:image/svg+xml,%3Csvg width=\'6\' height=\'6\' viewBox=\'0 0 6 6\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23000000\' fill-opacity=\'1\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M5 0h1L0 6V5zM6 5v1H5z\'/%3E%3C/g%3E%3C/svg%3E')`,
        backgroundSize: '4px 4px'
      }"
    />
  </section>
</template>

<script>
export default {
  name: 'ContactSection',
};
</script>

<style scoped>
textarea {
  resize: none;
}

/* Hover effects */
.group:hover .group-hover\:bg-red-100 {
  background-color: rgba(254, 226, 226, 1);
}

/* Smooth transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
</style>