<template>
  <section class="relative min-h-[400px] bg-gradient-to-b from-gray-50 to-white">
    <!-- Header pattern dengan warna TRI ASA -->
    <div class="absolute top-0 left-0 w-full h-16 bg-gradient-to-b from-red-600 to-red-500"></div>
    
    <!-- Main content -->
    <div class="container mx-auto px-4 pt-24 pb-12 relative">
      <div class="max-w-3xl mx-auto">
        <!-- Content box -->
        <div class="bg-white rounded shadow-md border border-gray-200 p-8">
          <!-- Heading dengan warna TRI ASA -->
          <div class="mb-6 pb-4 border-b-2 border-red-500">
            <h2 class="text-3xl font-bold text-red-600">
              Tentang Kami
            </h2>
          </div>
          
          <!-- Content -->
          <div class="prose prose-lg text-gray-600 mb-8">
            <p class="leading-relaxed">
              Didirikan tahun 2010, yang bertujuan turut serta dalam kegiatan 
              beberapa perusahaan atau pabrik dengan jalan menerapkan keahlian...
            </p>
          </div>
          
          <!-- Mengubah button menjadi router-link -->
          <router-link 
            to="/about"
            class="inline-block px-6 py-2 text-sm font-bold text-white 
                   bg-gradient-to-b from-red-500 to-red-600
                   hover:from-red-600 hover:to-red-700
                   rounded border border-red-700 shadow
                   transition-colors duration-200"
          >
            Baca selengkapnya →
          </router-link>
        </div>
      </div>
    </div>
    
    <!-- Footer pattern -->
    <div class="absolute bottom-0 left-0 w-full">
      <div class="h-8 bg-gradient-to-b from-transparent to-gray-50"></div>
      <div class="h-6 bg-gradient-to-b from-gray-50 to-gray-100"></div>
    </div>
    
    <!-- Subtle texture overlay -->
    <div 
      class="absolute inset-0 opacity-5 pointer-events-none"
      :style="{
        backgroundImage: `url('data:image/svg+xml,%3Csvg width=\'6\' height=\'6\' viewBox=\'0 0 6 6\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23000000\' fill-opacity=\'1\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M5 0h1L0 6V5zM6 5v1H5z\'/%3E%3C/g%3E%3C/svg%3E')`,
        backgroundSize: '4px 4px'
      }"
    />
  </section>
</template>

<script>
export default {
  name: 'AboutUsSection',
}
</script>

<style scoped>
/* Tambahan styling jika diperlukan */
section {
  position: relative;
  border-radius: 0.5rem;
  min-height: 400px;
}

/* Pastikan router-link bisa diklik */
.router-link-active {
  pointer-events: auto;
}
</style>