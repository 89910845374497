<template>
  <div>
    <HeaderSection />

    <!-- Main Container -->
    <div class="container mx-auto py-12">
      <h1 class="text-4xl font-bold text-gray-900 mb-4">Layanan Kami</h1>
      <p class="text-gray-600 mb-12">Kebutuhan Anda adalah prioritas kami</p>

      <div class="grid grid-cols-1 lg:grid-cols-4 gap-12">
        <!-- Product Display -->
        <div class="col-span-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div class="bg-white p-6 shadow rounded-lg">
            <img src="/produk/produk11.png" alt="Basic Tee 8-Pack" class="mb-4 w-full">
            <h4 class="text-lg font-semibold text-gray-900">Pabrikasi</h4>
            <p class="text-sm text-gray-600 mb-2">Mengerjakan proses pabrikasi yang sudah dipersiapkan segala macam alat dan mesin-mesin untuk melakukan proses produksi dan pekerjaan-pekerjaan pabrikasi lainnya.</p>
            <router-link 
              to="/detail-pabrikasi" class="inline-block px-6 py-2 text-sm font-semibold leading-6 text-white bg-green-500 rounded-md hover:bg-green-600 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Baca selengkapnya
            </router-link>
          </div>

          <div class="bg-white p-6 shadow rounded-lg">
            <img src="/produk/produk22.png" alt="Basic Tee" class="mb-4 w-full">
            <h4 class="text-lg font-semibold text-gray-900">Engineering, Precission Part, Bubut & Milling</h4>
            <p class="text-sm text-gray-600 mb-2">Mengerjakan pembuatan part dengan ketelitian dan presisi tinggi, seperti part-part mesin industri.</p>
            <router-link 
              to="/detail-engineering" class="inline-block px-6 py-2 text-sm font-semibold leading-6 text-white bg-green-500 rounded-md hover:bg-green-600 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Baca selengkapnya
            </router-link>
          </div>

          <div class="bg-white p-6 shadow rounded-lg">
            <img src="/produk/produk33.png" alt="Basic Tee" class="mb-4 w-full">
            <h4 class="text-lg font-semibold text-gray-900">Sipil & Konstruksi</h4>
            <p class="text-sm text-gray-600 mb-2">Merancang, membangun, merenovasi gedung dan infrastruktur</p>
            <router-link 
              to="/detail-sipil-konstruksi" class="inline-block px-6 py-2 text-sm font-semibold leading-6 text-white bg-green-500 rounded-md hover:bg-green-600 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Baca selengkapnya
            </router-link>
          </div>

          <div class="bg-white p-6 shadow rounded-lg">
            <img src="/produk/produk44.png" alt="Basic Tee" class="mb-4 w-full">
            <h4 class="text-lg font-semibold text-gray-900">Tenda & Terpal</h4>
            <p class="text-sm text-gray-600 mb-2">Divisi ini adalah bagian yang khusus membaut tenda dan terpal</p>
            <router-link 
              to="/detail-tenda-terpal" class="inline-block px-6 py-2 text-sm font-semibold leading-6 text-white bg-green-500 rounded-md hover:bg-green-600 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Baca selengkapnya
            </router-link>
          </div>

          <div class="bg-white p-6 shadow rounded-lg">
            <img src="/produk/produk55.png" alt="Basic Tee" class="mb-4 w-full">
            <h4 class="text-lg font-semibold text-gray-900">Alumunium & Kaca</h4>
            <p class="text-sm text-gray-600 mb-2">Divisi Ini Khusus pengerjaan dari bahan dasar Alumunium</p>
            <router-link 
              to="/detail-alumunium-kaca" class="inline-block px-6 py-2 text-sm font-semibold leading-6 text-white bg-green-500 rounded-md hover:bg-green-600 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Baca selengkapnya
            </router-link>
          </div>

          <div class="bg-white p-6 shadow rounded-lg">
            <img src="/produk/produk66.png" alt="Kinda White Basic Tee" class="mb-4 w-full">
            <h4 class="text-lg font-semibold text-gray-900">Powder Coating</h4>
            <p class="text-sm text-gray-600 mb-2">Pengecetan kering pelapisan dengan suatu film. Dalam bentuk film bubuk digunakan</p>
            <router-link 
              to="/detail-powder-coating" class="inline-block px-6 py-2 text-sm font-semibold leading-6 text-white bg-green-500 rounded-md hover:bg-green-600 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Baca selengkapnya
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'ProductOrServiceView',
  components: {
    HeaderSection,
    FooterSection,
  },
};
</script>

<style scoped>
/* Optional additional styles */
</style>