<template>
  <div class="flex">
    <!-- Sidebar -->
    <Sidebar />

    <!-- Main content -->
    <div class="flex-grow p-6">
      <h1 class="text-2xl font-bold mb-4">Page Control</h1>
      <p>Manage the content and layout of your pages from here.</p>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/SidebarDashboard.vue';

export default {
  name: 'PageControlView',
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
/* Styling for the flex layout */
.flex {
  display: flex;
}
</style>