<template>
    <div class="fixed bottom-6 right-6 z-50">
      <!-- Floating Button -->
      <a :href="whatsAppLink" target="_blank" class="bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg transition">
        <!-- WhatsApp Icon -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 24 24">
          <path d="M12.004 2c-5.521 0-10.001 4.479-10.001 10.001 0 1.767.466 3.482 1.349 4.988l-1.541 5.628 5.769-1.514c1.438.791 3.074 1.213 4.818 1.213 5.521 0 10.001-4.479 10.001-10.001s-4.48-10.001-10.001-10.001zm5.204 14.374c-.176.496-.992.917-1.39.917-.387 0-2.153-.043-4.16-1.688-1.7-1.392-2.811-3.125-3.144-3.653-.393-.628-.667-1.394-.667-1.758 0-.363.084-.569.466-.569.382 0 .758.044 1.092.044.322 0 .596-.116.846.467.268.609.902 2.121.98 2.276.07.153.056.295-.014.39-.072.1-.145.218-.212.296-.071.079-.15.168-.217.232-.149.14-.117.246.044.419.339.37 1.417 1.209 2.29 1.552.278.116.493.153.676.153.217 0 .391-.048.538-.235.155-.19.657-.767.798-1.025.138-.253.278-.219.493-.126.217.089 1.39.656 1.623.774.231.116.385.172.447.269.063.095.063.561-.112 1.059z"/>
        </svg>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Ganti nomor WhatsApp sesuai kebutuhan
        whatsAppNumber: "6285697464325", // Ganti dengan nomor WhatsApp tujuan tanpa "+" dan spasi
      };
    },
    computed: {
      whatsAppLink() {
        return `https://wa.me/${this.whatsAppNumber}`;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Custom styling for the floating button */
  .fixed {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 50;
  }
  
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #25d366; /* WhatsApp green */
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s;
  }
  
  a:hover {
    background-color: #128c7e; /* Darker WhatsApp green */
  }
  
  svg {
    width: 32px;
    height: 32px;
  }
  </style>
  