<template>
  <div>
    <HeaderSection />

    <section class="py-10 px-6 bg-blue-50">
      <div class="container mx-auto">
        <!-- Title Section -->
        <h2 class="text-2xl font-bold text-gray-900 mb-4">2. DIVISI MACHINING ENGINEERING, PRECISSION PART, BUBUT & MILLING</h2>
        <p class="text-gray-700 mb-4">
          Mengerjakan pembuatan part dengan ketelitian dan presisi tinggi, seperti part-part mesin industri.
        </p>
        <div class="flex flex-wrap items-center mb-6">
          <span class="font-bold text-gray-900 mr-2">Manager :</span>
          <span class="text-purple-600 font-semibold mr-6">KARTA</span>
          <span class="ml-auto font-semibold text-blue-800">
            Phone : 0852 1384 3686
          </span>
        </div>

        <!-- Machine Images Section -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <div class="text-center">
            <img src="/engineering/engineering1.png" alt="MESIN BUBUT MAGNUM TECH FEL-1640 GCY" class="rounded-full border-2 border-blue-300 w-48 h-48 object-cover mx-auto mb-2" />
            <p class="text-gray-800 font-semibold">MESIN BUBUT MAGNUM TECH FEL-1640 GCY</p>
          </div>
          <div class="text-center">
            <img src="/engineering/engineering2.png" alt="MESIN MILLING ZONTECH" class="rounded-full border-2 border-blue-300 w-48 h-48 object-cover mx-auto mb-2" />
            <p class="text-gray-800 font-semibold">MESIN MILLING ZONTECH</p>
          </div>
        </div>


        <!-- Link to Google Drive as Button -->
        <div class="mt-6 text-center">
          <a
            href="https://drive.google.com/file/d/1vJqYqeEoTSZiEWWEzyNrqz08ZDowNYyz/view?usp=sharing"
            target="_blank"
            class="inline-block px-6 py-2 bg-green-500 text-white font-semibold rounded-lg shadow hover:bg-green-600 transition duration-300 ease-in-out"
          >
          Download Catalog
          </a>
        </div>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'DetailEngineering',
  components: {
    HeaderSection,
    FooterSection,
  },
  data() {
    return {
      parts: [
        { name: 'Roller Conveyor', image: 'path/to/part1.jpg' },
        { name: 'Roller Conveyor', image: 'path/to/part2.jpg' },
        { name: 'Precision Part 1', image: 'path/to/part3.jpg' },
        { name: 'Precision Part 2', image: 'path/to/part4.jpg' },
        { name: 'Precision Part 3', image: 'path/to/part5.jpg' },
        { name: 'Precision Part 4', image: 'path/to/part6.jpg' },
      ],
    };
  },
};
</script>

<style scoped>
section {
  border-radius: 0.5rem;
}

img.rounded-full {
  max-width: 100px;
  max-height: 100px;
}
</style>
